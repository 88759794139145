import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { PageTreeFilterValues } from '@/declarations/PageTreeFilterValues';
import { PageFolder } from '@/declarations/models/Folder';
import { Page } from '../../declarations/models/Page';
import { RecursiveSelectionState } from '../../hooks/useRecursiveSelectionState';
import { ContentViewSortDirection, ContentViewSortProperty } from './ContentView';
import { Status } from '../../declarations/models/Status';

export enum ContentViewContentContext {
  PAGE = 'page',
  EVENT = 'event',
}

export interface ContentViewContextValue {
  contentContext: ContentViewContentContext;
  pages: Array<Page>;
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  selectionState: RecursiveSelectionState<Page>;
  selectionMode: boolean;
  setSelectionMode: Dispatch<SetStateAction<boolean>>;
  movePagesMode: boolean;
  movePagesInFolderMode: boolean;
  setMovePagesMode: Dispatch<SetStateAction<boolean>>;
  setMovePagesInFolderMode: Dispatch<SetStateAction<boolean>>;
  toggleLastEditedPagesView: () => void;
  expandedPageIds: Array<string>;
  setExpandedPageIds: Dispatch<SetStateAction<Array<string>>>;
  allExpanded: boolean;
  filterValues: PageTreeFilterValues;
  setFilterValues: Dispatch<SetStateAction<PageTreeFilterValues>>;
  hasFilterApplied: boolean;
  reloadPages: () => void;
  deletePage: (page: Page) => Promise<void>;
  changePageStatus: (page: Page, newStatus: Status) => Promise<void>;
  openCreatePageModal: (parent?: Page) => void;
  closeCreatePageModal: () => void;
  portalUrl: string;
  sortProperty: ContentViewSortProperty;
  setSortProperty: Dispatch<SetStateAction<ContentViewSortProperty>>;
  sortDirection: ContentViewSortDirection;
  setSortDirection: Dispatch<SetStateAction<ContentViewSortDirection>>;
  sortFn: (page1: Page, page2: Page) => number;
  hasDraft: (pageId: number) => boolean;
  activePageFolder: PageFolder | null;
  setActivePageFolder: (folder: PageFolder | null) => void;
  siteFolders: Array<PageFolder>;
  reloadSiteFolders: () => void;
}
export const ContentViewContext = createContext<ContentViewContextValue | null>(null);

export function useContentView(): ContentViewContextValue {
  const value = useContext(ContentViewContext);
  if (!value) {
    throw new Error('ContentViewContext accessed before init');
  }
  return value;
}
