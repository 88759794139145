import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Status } from '@/declarations/models/Status';
import { includePage } from '@/utils/functions';
import { FolderView } from '@/views/ContentView/PageTree/FolderView';
import { PageFolder } from '@/declarations/models/Folder';
import { PageTreeFilterValues } from '@/declarations/PageTreeFilterValues';
import { notNil } from '@/utils/object';
import { Page } from '../../../declarations/models/Page';
import PageTreeContent from './PageTreeContent';
import { ContentViewContentContext, useContentView } from '../ContentViewContext';
import { useStore } from '../../../components/store/Store';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';

function includeInPageIncludingFolder(
  filterValues: PageTreeFilterValues,
  page: Page,
  folder: PageFolder | null,
): boolean {
  if (!folder && !filterValues.query) {
    return page.page_folder_id == null && includePage(filterValues, page);
  }
  if (!folder && filterValues.query) {
    return includePage(filterValues, page);
  }
  if (folder && filterValues.query) {
    return (
      page.page_folder_id != null &&
      [folder.id, ...(folder.descendant_ids || [])].includes(page.page_folder_id) &&
      includePage(filterValues, page)
    );
  }

  if (folder) {
    return page.page_folder_id === folder.id && includePage(filterValues, page);
  }

  return page.page_folder_id == null && includePage(filterValues, page);
}

const PageTree: FC = () => {
  const { t } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();
  const {
    expandedPageIds,
    setExpandedPageIds,
    selectionState,
    filterValues,
    hasFilterApplied,
    sortFn,
    pages,
    contentContext,
    activePageFolder,
  } = useContentView();
  const { getRootNodes, getChildren, hasChildren, itemCount } = selectionState;
  const isEventView = contentContext === ContentViewContentContext.EVENT;

  const defaultExpandedIds = useMemo<Array<string>>(() => {
    const roots = getRootNodes().map((n) => String(n.id || ''));
    return roots.length <= 5 ? roots : [];
  }, [getRootNodes]);

  const visibleNodeIds = useMemo<null | Array<string | number>>(() => {
    if (!hasFilterApplied) {
      return null;
    }
    const getVisibleNodeIds = (page: Page): Array<string | number> => {
      const key = page.id || 0;
      if (hasChildren(key)) {
        const visibleChildren = getChildren(key).reduce((ids, child) => {
          ids.push(...getVisibleNodeIds(child).filter((id) => !ids.includes(id)));
          return ids;
        }, [] as Array<string | number>);

        if (visibleChildren.length > 0) {
          visibleChildren.push(key);
          return visibleChildren;
        }
      }
      return includePage(filterValues, page) ? [key] : [];
    };

    return getRootNodes().reduce((ids, root) => {
      ids.push(...getVisibleNodeIds(root));
      return ids;
    }, [] as Array<number | string>);
  }, [filterValues, hasFilterApplied, getRootNodes, getChildren, hasChildren]);

  useEffect(() => {
    if (hasFilterApplied && !!visibleNodeIds?.length) {
      setExpandedPageIds(visibleNodeIds.map(String));
    } else {
      setExpandedPageIds(defaultExpandedIds);
    }
  }, [hasFilterApplied, defaultExpandedIds, setExpandedPageIds, visibleNodeIds, getRootNodes]);

  const renderNode = (page: Page) => {
    const pageId = page.id || 0;
    const childNodes = getChildren(pageId).sort(sortFn);
    if (visibleNodeIds && !visibleNodeIds.includes(pageId)) {
      return null;
    }
    return (
      <TreeItem
        key={pageId}
        nodeId={String(pageId)}
        label={<PageTreeContent page={page} diffuse={hasFilterApplied && !includePage(filterValues, page)} />}>
        {hasChildren(pageId) ? childNodes.map(renderNode) : null}
      </TreeItem>
    );
  };

  if (!itemCount) {
    return null;
  }

  const frontpage = pages?.find((page) => page.id === state.selectedSiteDefaultPageId);
  const pagesToDisplay = pages?.map((page) => {
    if (includeInPageIncludingFolder(filterValues, page, activePageFolder)) {
      return (
        <Container
          fullWidth
          key={page.id}
          pl={isEventView ? 0 : 2}
          sx={{
            // borderBottom: `1px solid #ccc`,
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            backgroundColor:
              isEventView && page.status === Status.PUBLISHED
                ? Styles.Colors.STRONG_GREEN_TRANSPARENT
                : Styles.Colors.LIGHT_GREY,
            '&:hover': {
              backgroundColor:
                isEventView && page.status === Status.PUBLISHED
                  ? Styles.Colors.MEDIUM_GREEN_TRANSPARENT
                  : Styles.Colors.LIGHTEST_GREY,
            },
          }}>
          <PageTreeContent page={page} displayBreadcrumbs={!isEventView} />
        </Container>
      );
    }
    return null;
  });

  return (
    <>
      {hasFilterApplied || isEventView ? (
        <>
          {isEventView && state.selectedSite?.id != null && <FolderView siteId={state.selectedSite.id} />}
          {pagesToDisplay}
          {Boolean(pagesToDisplay?.filter(notNil).length === 0) && (
            <Container fullWidth pl={2}>
              {tComponents('PageTree.NoPagesFound')}
            </Container>
          )}
        </>
      ) : (
        <>
          {frontpage && (
            <Container
              fullWidth
              px={1}
              sx={{
                backgroundColor: Styles.Colors.LIGHTEST_BLUE,
                '&:hover': {
                  backgroundColor: Styles.Colors.LIGHT_BLUE,
                },
                pl: 3,
              }}>
              <PageTreeContent page={frontpage} hideBorder displayBreadcrumbs={false} />
            </Container>
          )}
          <TreeView
            sx={{ width: '100%' }}
            id='page-tree'
            aria-label={t('components.PageTree.PageTreeDescription', {
              siteName: state.selectedSite?.name || 'unknown',
            })}
            expanded={expandedPageIds}
            onNodeToggle={(_, ex) => setExpandedPageIds(ex)}
            defaultCollapseIcon={<MaterialSymbol name='expand_more' />}
            defaultExpandIcon={<MaterialSymbol name='chevron_right' />}
            disableSelection>
            {getRootNodes(state.selectedSiteDefaultPageId ?? undefined)
              .filter((page) => page.id !== state.selectedSiteDefaultPageId)
              .sort(sortFn)
              .map(renderNode)}
          </TreeView>
        </>
      )}
    </>
  );
};

export default PageTree;
