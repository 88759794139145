import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GenericMedia } from '@/declarations/GenericMedia';
import { M24PageFinderSelectedItem } from '@/components/Finder/M24PageFinder/M24PageFinderSelectedItem';
import { SortSelector } from '@/components/SortSelector';
import { SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import { Status } from '@/declarations/models/Status';
import { Site } from '@/declarations/models/Site';
import { findDefaultPageId } from '@/utils/functions';
import { Checkbox, FormControlLabel } from '@mui/material';
import { PageTreeFilterValues } from '@/declarations/PageTreeFilterValues';
import M24PageFinderPageTree from './M24PageFinderPageTree/M24PageFinderPageTree';
import { Page } from '../../../declarations/models/Page';
import { RecursiveSelectionState, useRecursiveSelectionState } from '../../../hooks/useRecursiveSelectionState';
import { apiTimestampToDate } from '../../../utils/dates';
import Loader from '../../Loader';
import { useStore } from '../../store/Store';
import Container from '../../Container';
import M24PageFinderPageTreeToolbar from './M24PageFinderPageTreeToolbar/M24PageFinderPageTreeToolbar';
import Layout from '../../Layout';
import FinderLayout from '../FinderLayout';
import { Api } from '../../../services/Api';
import { MainCategory } from '../../../declarations/Category';

type SortProperty = keyof Pick<Page, 'title' | 'status' | 'updated_at'>;

interface M24PageFinderContextValue {
  pages: Array<Page>;
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  selectionState: RecursiveSelectionState<Page>;
  expandedPageIds: Array<string>;
  setExpandedPageIds: Dispatch<SetStateAction<Array<string>>>;
  allExpanded: boolean;
  filterValues: PageTreeFilterValues;
  setFilterValues: Dispatch<SetStateAction<PageTreeFilterValues>>;
  hasFilterApplied: boolean;
  reloadPages?: () => void;
  sortProperty: SortProperty;
  setSortProperty: Dispatch<SetStateAction<SortProperty>>;
  sortDirection: SortDirection;
  setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  sortFn: (page1: Page, page2: Page) => number;
  onSelect: (item: Page) => void;
  availableMainCategories: Array<MainCategory>;
}
const M24PageFinderContent = createContext<M24PageFinderContextValue | null>(null);

export function useM24PageFinderContentView(): M24PageFinderContextValue {
  const value = useContext(M24PageFinderContent);
  if (!value) {
    throw new Error('M24PageFinderContent accessed before init');
  }
  return value;
}

export interface M24PageFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<Page>>) => void;
  multiSelect?: boolean;
  isEvent?: boolean;
  hideSiteSelector?: boolean;
  type?: 'page' | 'template';
}

const defaultFilterValues: PageTreeFilterValues = Object.freeze({
  query: '',
  statuses: [],
  main_category: undefined,
  sub_categories: [],
  includePastEvents: true,
  location_ids: [],
});

/**
 * Page tree similar to ContentView in a finder. Most components are copied from ContentView and customized to suit
 * the finder's use case.
 * @param open
 * @param onClose
 * @param onSelect
 * @param multiSelect
 * @param availableMainCategories
 */
export const M24PageFinder: FC<M24PageFinderProps> = ({
  open,
  onClose,
  onSelectionConfirmed,
  multiSelect,
  isEvent,
  hideSiteSelector,
  type = 'page',
}) => {
  const { t } = useTranslation('common');

  const { state } = useStore();
  const { selectedSiteLanguage } = state;
  const selectedSiteId = state.selectedSite?.id || 0;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<Array<Page>>([]);
  const [selectedSharedSite, setSelectedSharedSite] = useState<Site | null>(null);
  const [selectedSharedLanguage, setSelectedSharedLanguage] = useState<string | null>(selectedSiteLanguage);

  const [expandedPageIds, setExpandedPageIds] = useState<Array<string>>([]);
  const [filterValues, setFilterValues] = useState<PageTreeFilterValues>({
    ...defaultFilterValues,
    main_category: isEvent ? MainCategory.EVENT : undefined,
  });

  const [sortProperty, setSortProperty] = useState<SortProperty>('title');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

  const _onClose = () => {
    onClose();
    setExpandedPageIds([]);
    setFilterValues({
      ...defaultFilterValues,
      main_category: isEvent ? MainCategory.EVENT : undefined,
    });
  };

  const sortFn = useCallback(
    (page1: Page, page2: Page): number => {
      let a: string | number;
      let b: string | number;

      switch (sortProperty) {
        case 'status':
          a = (page1.status ? t(`Status.${page1.status}`) : '').toLowerCase();
          b = (page2.status ? t(`Status.${page2.status}`) : '').toLowerCase();
          break;
        case 'updated_at':
          a = apiTimestampToDate(page1.updated_at || '')?.getTime() || 0;
          b = apiTimestampToDate(page2.updated_at || '')?.getTime() || 0;
          break;
        case 'title':
        default:
          a = String(page1.title || '').toLowerCase();
          b = String(page2.title || '').toLowerCase();
          break;
      }

      let order = 0;
      if (a < b) {
        order = -1;
      } else if (a > b) {
        order = 1;
      }
      return sortDirection === SortDirection.ASC ? order : -order;
    },
    [sortProperty, sortDirection, t],
  );
  const pagesForSelectedLanguage = useMemo<Array<Page>>(() => {
    return pages.filter((page) => page.locale === (selectedSharedLanguage ?? selectedSiteLanguage));
  }, [pages, selectedSharedLanguage, selectedSiteLanguage]);

  const selectionState = useRecursiveSelectionState<Page>(
    pagesForSelectedLanguage,
    (page) => page.id || 0,
    (page) => page.parent_id || null,
    true,
  );

  const selectedItems = useMemo<Array<GenericMedia<Page>>>(() => {
    return selectionState.selectedItems.map((item) => ({
      id: `${item.id || 0}`,
      title: item.title || '',
      url: item.image_src || '',
      mimetype: item.image?.mimetype,
      description: item.description || '',
      source: item,
    }));
  }, [selectionState.selectedItems]);

  const hasFilterApplied = useMemo<boolean>(
    () =>
      !!filterValues.query ||
      !!filterValues.statuses.length ||
      typeof filterValues.main_category === 'string' ||
      !!filterValues.sub_categories.length ||
      !!filterValues.location_ids.length,
    [filterValues],
  );

  const contextValue = useMemo<M24PageFinderContextValue>(() => {
    // const handleSelect = (item: Page) => {
    //   onSelect(item);
    //   if (!multiSelect) onClose();
    // };

    const handleSelect = (item: Page) => {
      if (selectionState.isSelected(item.id!)) {
        selectionState.deselect(item.id!);
      } else {
        if (!multiSelect) {
          selectionState.clear();
        }
        selectionState.select(item.id!);
      }
    };

    return {
      pages: pagesForSelectedLanguage,
      selectedPageIds: selectionState.selectedItems.map((item) => item.id || 0),
      setPages,
      selectionState,
      filterValues,
      setFilterValues,
      expandedPageIds,
      setExpandedPageIds,
      allExpanded: expandedPageIds.length === selectionState.itemCount,
      hasFilterApplied,
      sortProperty,
      setSortProperty,
      sortDirection,
      setSortDirection,
      sortFn,
      onSelect: handleSelect,
      availableMainCategories: isEvent
        ? [MainCategory.EVENT]
        : [MainCategory.INFORMATION, MainCategory.EXHIBITION, MainCategory.NONE, MainCategory.KNOWLEDGE],
    };
  }, [
    pagesForSelectedLanguage,
    selectionState,
    filterValues,
    expandedPageIds,
    hasFilterApplied,
    sortProperty,
    sortDirection,
    sortFn,
    isEvent,
    multiSelect,
  ]);

  function loadPages(): [AbortController['abort'], Promise<Page[]>] {
    const ctx = Api.getPagesListForSite(selectedSharedSite?.id ?? selectedSiteId, {
      is_event: isEvent,
      past_events: filterValues.includePastEvents,
      location_ids: filterValues.location_ids,
    });
    const promise = new Promise<Page[]>((res, rej) => {
      ctx.fetchDirect(null).then((fetchedPages) => {
        if (fetchedPages) {
          res(fetchedPages);
        } else {
          rej(new Error('Error fetching pages'));
        }
      });
    });

    return [ctx.abort, promise];
  }

  function loadTemplates(): [AbortController['abort'], Promise<Page[]>] {
    const ctx = Api.listPageTemplates(selectedSharedSite?.id ?? selectedSiteId);
    const promise = new Promise<Page[]>((res, rej) => {
      ctx.fetchDirect(null).then((fetchedTemplates) => {
        if (fetchedTemplates?.page_templates) {
          res(fetchedTemplates.page_templates);
        } else {
          rej(new Error('Error fetching templates'));
        }
      });
    });

    return [ctx.abort, promise];
  }

  useEffect(() => {
    if (!open) return;
    let unmounted = false;

    const [abort, pagePromise] = type === 'template' ? loadTemplates() : loadPages();

    pagePromise.then(setPages).finally(() => {
      if (!unmounted) setIsLoading(false);
    });

    return () => {
      unmounted = true;
      abort();
    };
  }, [open, selectedSharedSite, selectedSiteId, isEvent, filterValues, type]);

  const selectedFrontpage = useMemo<Page | undefined>(() => {
    if (selectedSharedSite && selectedSharedLanguage) {
      const id = findDefaultPageId(selectedSharedSite, selectedSharedLanguage);
      return pages.find((p) => p.id === id);
    }
    return pages.find((p) => p.id === state.selectedSiteDefaultPageId);
  }, [pages, selectedSharedLanguage, selectedSharedSite, state.selectedSiteDefaultPageId]);

  // If switching to a site that doesn't have the selected language, switch to the first language available.
  useEffect(() => {
    if (selectedSharedSite?.site_contents?.map((c) => c.locale).includes(selectedSharedLanguage ?? '')) return;
    setSelectedSharedLanguage(
      selectedSharedSite?.site_contents?.[0].locale ?? selectedSharedSite?.default_language ?? selectedSiteLanguage,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSharedSite]);

  const sortOption: SortOption = useMemo(() => {
    return {
      sortBy: sortProperty as SortType,
      order: sortDirection as SortDirection,
    };
  }, [sortProperty, sortDirection]);
  const setSortOption = (option: SortOption) => {
    setSortProperty(option.sortBy as typeof sortProperty);
    setSortDirection(option.order);
  };

  return (
    <M24PageFinderContent.Provider value={contextValue}>
      <FinderLayout<Page>
        open={open}
        onClose={_onClose}
        selectedItems={selectedItems}
        renderSelectedItem={(item) => (
          <M24PageFinderSelectedItem item={item} onRemove={() => selectionState.deselect(item.source.id!)} />
        )}
        onConfirmSelection={() => {
          onSelectionConfirmed(selectedItems);
          _onClose();
        }}>
        <Layout
          headerContent={
            <M24PageFinderPageTreeToolbar
              isEvent={isEvent}
              site={state.selectedSite}
              selectedSharedSite={selectedSharedSite}
              onSelectSharedSite={setSelectedSharedSite}
              selectedLanguage={selectedSharedLanguage}
              onSelectLanguage={setSelectedSharedLanguage}
              hideSiteSelector={hideSiteSelector}
              selectorType={type}
            />
          }>
          {(isEvent || hasFilterApplied) && (
            <Container spaceBetween fullWidth px={4}>
              <SortSelector
                availableOptions={[SortType.TITLE, SortType.UPDATED_AT, SortType.STATUS]}
                sortOption={sortOption}
                setSortOption={setSortOption}
              />
              <Container right fullWidth>
                {[Status.PUBLISHED, Status.DRAFT, Status.ARCHIVED].map((status) => (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        disableRipple
                        checked={filterValues.statuses.includes(status)}
                        onChange={() => {
                          setFilterValues((prev) => ({
                            ...prev,
                            statuses: prev.statuses.includes(status)
                              ? prev.statuses.filter((s) => s !== status)
                              : [...prev.statuses, status],
                          }));
                        }}
                      />
                    }
                    label={t(`Status.${status}`)}
                    labelPlacement='end'
                  />
                ))}
                {isEvent && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableRipple
                        checked={!filterValues.includePastEvents}
                        onChange={() => {
                          setFilterValues((prev) => ({
                            ...prev,
                            includePastEvents: !prev.includePastEvents,
                          }));
                        }}
                      />
                    }
                    label={t(`Status.hide_past_events`)}
                    labelPlacement='end'
                  />
                )}
              </Container>
            </Container>
          )}
          <Container p={1} column top fullHeight fullWidth>
            {isLoading ? <Loader /> : <M24PageFinderPageTree isEvent={isEvent} frontpage={selectedFrontpage} />}
          </Container>
        </Layout>
      </FinderLayout>
    </M24PageFinderContent.Provider>
  );
};

export default M24PageFinder;
