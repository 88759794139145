import React, { FC, useEffect } from 'react';
import { useApi } from '@/hooks/useApi';
import { Api } from '@/services/Api';
import { useSearchParams } from 'react-router-dom';
import { PageFolder } from '@/declarations/models/Folder';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { formatAPITimestamp } from '@/utils/dates';
import { useContentView } from '@/views/ContentView/ContentViewContext';
import DeleteButton from '@/components/DeleteButton';
import ActionMenu from '@/components/ActionMenu';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { PageFolderAutocomplete } from '@/views/ContentView/PageTree/PageFolderAutocomplete';

interface FolderViewProps {
  siteId: number;
}

function findFolder(folders: PageFolder[], id: number): PageFolder | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const folder of folders) {
    if (folder.id === id) {
      return folder;
    }
    if (folder.children) {
      const found = findFolder(folder.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

function findBreadCrumb(folders: PageFolder[], folder: PageFolder): PageFolder[] {
  const breadCrumb: PageFolder[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const f of folders) {
    if (f.id === folder.id) {
      breadCrumb.push(f);
      return breadCrumb;
    }
    if (f.children) {
      const found = findBreadCrumb(f.children, folder);
      if (found.length > 0) {
        breadCrumb.push(f);
        breadCrumb.push(...found);
        return breadCrumb;
      }
    }
  }

  return breadCrumb;
}

interface FolderListItemProps {
  folder: PageFolder;
  onClick: (folder: PageFolder) => void;
  onDelete: (folderId: number) => void;
  onInitMove: (folders: PageFolder[]) => void;
  onEdit: (folder: PageFolder) => void;
}
const FolderListItem = ({ folder, onClick, onDelete, onInitMove, onEdit }: FolderListItemProps) => {
  const confirmFn = useConfirmDialog();
  const { t: tComponents } = useTranslation('components');
  const [editMode, setEditMode] = React.useState(false);
  const [title, setTitle] = React.useState(folder.title);
  const [focused, setFocused] = React.useState(false);
  return (
    <Container
      fullWidth
      left
      onMouseOver={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
      sx={{
        border: `1px solid ${Styles.Colors.DARK_GREY}`,
        p: 1,
      }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}>
        <Container>
          <MaterialSymbol name='folder' />
          {!editMode && (
            <>
              <Button onClick={() => onClick(folder)}>{folder.title}</Button>
              {focused && (
                <Button
                  startIcon={<MaterialSymbol name='edit' />}
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditMode(true);
                  }}>
                  {tComponents('FolderView.editName')}
                </Button>
              )}
            </>
          )}
          {editMode && (
            <TextField
              onClick={(e) => e.stopPropagation()}
              size='small'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setEditMode(false);
                  if (title !== folder.title) {
                    onEdit({ ...folder, title });
                  }
                } else if (e.key === 'Escape') {
                  setEditMode(false);
                  setTitle(folder.title);
                }
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(false);
                        if (title !== folder.title) {
                          onEdit({ ...folder, title });
                        }
                      }}>
                      <MaterialSymbol name='done' />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(false);
                        setTitle(folder.title);
                      }}>
                      <MaterialSymbol name='close' />
                    </IconButton>
                  </>
                ),
              }}
            />
          )}
        </Container>

        <Container
          left
          sx={{
            flexGrow: 1,
          }}>
          {/* todo: page count inside */}
        </Container>
      </Box>

      <Container>
        <Typography color='text.secondary' variant='caption'>
          {tComponents('FolderView.createdAtDate', { date: formatAPITimestamp(folder.created_at, 'date') })}
        </Typography>
        <ActionMenu id='sort-selector' ariaLabel='sort-selector' icon={<MaterialSymbol name='more_vert' />}>
          <MenuItem
            onClick={() => {
              confirmFn(
                <Box
                  sx={{
                    p: 2,
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: tComponents('FolderView.ConfirmDeleteFolderDialogHtml', { folderName: folder.title }),
                  }}
                />,
                {},
              ).then((confirmed) => {
                if (confirmed) {
                  onDelete(folder.id);
                }
              });
            }}>
            <ListItemIcon>
              <MaterialSymbol color='error' name='delete' />
            </ListItemIcon>
            <ListItemText>{tComponents('FolderView.DeleteActionButton')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => onInitMove([folder])}>
            <ListItemIcon>
              <MaterialSymbol name='arrow_forward' />
            </ListItemIcon>
            <ListItemText>{tComponents('FolderView.MoveActionButton')}</ListItemText>
          </MenuItem>
        </ActionMenu>
      </Container>
    </Container>
  );
};

export const FolderView: FC<FolderViewProps> = ({ siteId }) => {
  const { activePageFolder, setActivePageFolder, reloadPages, siteFolders, reloadSiteFolders } = useContentView();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t: tComponents } = useTranslation('components');
  // const [currentFolder, setCurrentFolder] = React.useState<PageFolder | null>(null);
  useEffect(() => {
    if (searchParams.has('folder')) {
      const folderId = parseInt(searchParams.get('folder') as string, 10);
      const folder = findFolder(siteFolders, folderId);
      if (folder) {
        setActivePageFolder(folder);
      } else {
        setActivePageFolder(null);
      }
    } else {
      setActivePageFolder(null);
    }
  }, [siteFolders, searchParams, setActivePageFolder]);

  const [breadcrumbs, setBreadcrumbs] = React.useState<PageFolder[]>([]);
  useEffect(() => {
    if (activePageFolder) {
      const breadCrumbs = findBreadCrumb(siteFolders, activePageFolder);
      setBreadcrumbs(breadCrumbs);
    } else {
      setBreadcrumbs([]);
    }
  }, [activePageFolder, siteFolders]);

  const createNewFolder = async (title: string) => {
    await Api.createFolder(siteId, { title, parent_id: activePageFolder?.id }).fetchDirect(null);
    reloadSiteFolders();
  };

  const deleteFolder = async (folderId: number) => {
    await Api.deleteFolder(siteId, folderId).fetchDirect(null);
    reloadSiteFolders();
    reloadPages();
  };

  const [foldersToMove, setFoldersToMove] = React.useState<PageFolder[]>([]);
  const [showMoveDialog, setShowMoveDialog] = React.useState(false);
  const [moveTargetFolder, setMoveTargetFolder] = React.useState<PageFolder | null>(null);
  const confirmMoveFolders = async () => {
    const requests = foldersToMove.map((folder) => {
      return Api.updateFolder(siteId, folder.id, {
        ...folder,
        parent_id: moveTargetFolder?.id,
      }).fetchDirect(null);
    });
    await Promise.all(requests);
    reloadSiteFolders();
    reloadPages();
    setShowMoveDialog(false);
  };

  const initMoveFolders = (folders: PageFolder[]) => {
    setFoldersToMove(folders);
    setShowMoveDialog(true);
  };

  const updateFolder = async (folder: PageFolder) => {
    await Api.updateFolder(siteId, folder.id, folder).fetchDirect(null);
    reloadSiteFolders();
  };

  function navigateToFolder(folder: PageFolder) {
    setSearchParams({ folder: String(folder.id) });
  }

  return (
    <Container fullWidth column left>
      <Container fullWidth left gap={4}>
        {activePageFolder && Boolean(breadcrumbs?.length) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
            <MaterialSymbol name='folder' />
            <Button
              size='small'
              onClick={() => {
                searchParams.delete('folder');
                setSearchParams(searchParams);
              }}>
              {tComponents('FolderView.rootFolderName')}
            </Button>
            {breadcrumbs.map((folder, index) => (
              <div
                key={`crumb-${folder.id}`}
                style={{
                  display: 'contents',
                }}>
                <MaterialSymbol name='chevron_right' />
                {index === breadcrumbs.length - 1 ? (
                  <Typography
                    variant='subtitle1'
                    sx={{
                      fontWeight: 'bold',
                    }}>
                    {folder.title}
                  </Typography>
                ) : (
                  <Button size='small' onClick={() => navigateToFolder(folder)}>
                    {folder.title}
                  </Button>
                )}
              </div>
            ))}
          </Box>
        )}
        <Button
          size='small'
          variant='outlined'
          startIcon={<MaterialSymbol name='create_new_folder' />}
          onClick={() => createNewFolder(tComponents('FolderView.newFolderDefaultName'))}>
          {tComponents('FolderView.createNewFolder')}
        </Button>
      </Container>
      <Container fullWidth left column>
        {(activePageFolder?.children || siteFolders).map((folder) => (
          <FolderListItem
            key={`folder-${folder.id}`}
            folder={folder}
            onClick={() => navigateToFolder(folder)}
            onDelete={(folderId) => deleteFolder(folderId)}
            onInitMove={(folders) => initMoveFolders(folders)}
            onEdit={(f) => updateFolder(f)}
          />
        ))}
      </Container>
      <Dialog
        open={showMoveDialog}
        onClose={() => {
          setShowMoveDialog(false);
          setMoveTargetFolder(null);
        }}>
        <Container
          fullWidth
          column
          sx={{
            p: 2,
            gap: 2,
            minWidth: '400px',
          }}>
          <Typography variant='h4'>{tComponents('FolderView.MoveDialogTitle')}</Typography>
          <PageFolderAutocomplete
            folders={siteFolders.filter((folder) => foldersToMove.every((f) => f.id !== folder.id))}
            onChange={(targetFolder) => {
              if (targetFolder?.id) {
                setMoveTargetFolder(targetFolder);
              } else {
                setMoveTargetFolder(null);
              }
            }}
          />
          <Container>
            <Button onClick={() => setShowMoveDialog(false)}>{tComponents('FolderView.cancel')}</Button>
            <Button color='primary' variant='contained' onClick={() => confirmMoveFolders()}>
              {tComponents('FolderView.confirmMoveButton')}
            </Button>
          </Container>
        </Container>
      </Dialog>
    </Container>
  );
};
