import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ToolbarHeader } from '@/components/ToolbarHeader';
import Styles from '@/assets/js/Styles';
import PageTreeBulkOperations from '@/views/ContentView/PageTreeToolbar/PageTreeBulkOperations';
import { SortSelector } from '@/components/SortSelector';
import { EventCategory, MainCategory, SubCategory } from '@/declarations/Category';
import { LocationSelector } from '@/components/LocationSelector';
import { PageTreeFilterValues } from '@/declarations/PageTreeFilterValues';
import { PageFolder } from '@/declarations/models/Folder';
import { PageFolderAutocomplete } from '@/views/ContentView/PageTree/PageFolderAutocomplete';
import { useStore } from '../../components/store/Store';
import { Page } from '../../declarations/models/Page';
import PageTree from './PageTree/PageTree';
import Container from '../../components/Container';
import Loader from '../../components/Loader';
import { useRecursiveSelectionState } from '../../hooks/useRecursiveSelectionState';
import { Api, GetPagesListForSiteQueryParams } from '../../services/Api';
import { useApi } from '../../hooks/useApi';
import { apiTimestampToDate } from '../../utils/dates';
import { ContentViewContentContext, ContentViewContext, ContentViewContextValue } from './ContentViewContext';
import CreatePageModal from '../../components/CreatePageModal';
import { deepCopy } from '../../utils/object';
import { Status } from '../../declarations/models/Status';
import { SortDirection, SortType } from '../../declarations/models/SortOption';
import FilterCategories from './FilterSubCategories';

export type ContentViewSortProperty = keyof Pick<Page, 'title' | 'status' | 'updated_at'>;
export type ContentViewSortDirection = 'asc' | 'desc';

export interface ContentViewProps {
  contentContext: ContentViewContentContext;
}

const defaultFilterValues: PageTreeFilterValues = {
  query: '',
  statuses: [],
  sub_categories: [],
  lastEditedPagesView: false,
  includePastEvents: true,
  location_ids: [],
};

export const ContentView: FC<ContentViewProps> = ({ contentContext }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const hasRendered = useRef<Record<string, boolean | undefined>>({});

  const { state } = useStore();
  const { selectedSiteLanguage } = state;
  const selectedSiteId = state.selectedSite?.id || 0;

  const isEvent = contentContext === ContentViewContentContext.EVENT;

  const [eventParams, setEventParams] = useState<Partial<GetPagesListForSiteQueryParams>>({
    is_event: isEvent,
    order_by: SortType.EVENT_START,
    order: SortDirection.ASC,
    past_events: true,
    location_ids: [],
    sub_categories: [],
    main_category: undefined,
  });

  const [pages, loadingPages, reloadPages, setPages] = useApi(
    () => Api.getPagesListForSite(selectedSiteId, eventParams),
    [] as Array<Page>,
  );

  const [pagesWithDraft] = useApi(() => Api.getPageIdsWithDraft(selectedSiteId), { page_ids: [] as Array<number> });

  const [lastEditedPages, setLastEditedPages] = useState<Array<Page>>([]);

  const [domain, loadingDomain, reloadDomain] = useApi(() => Api.getDomainForSite(selectedSiteId), null);

  const loading = loadingPages || loadingDomain;

  const [portalUrl, setPortalUrl] = useState<string>('#');

  const [selectionMode, setSelectionMode] = useState<boolean>(false);
  const [movePagesMode, setMovePagesMode] = useState<boolean>(false);

  const [createPageModalOpen, setCreatePageModalOpen] = useState<boolean>(false);
  const [createPageRoot, setCreatePageRoot] = useState<Page | null>(null);

  const [expandedPageIds, setExpandedPageIds] = useState<Array<string>>([]);
  const [filterValues, setFilterValues] = useState<PageTreeFilterValues>(defaultFilterValues);
  const [filterCategoriesVisible, _setFilterCategoriesVisible] = useState<boolean>(false);
  const setFilterCategoriesVisible = useCallback((value: boolean) => {
    _setFilterCategoriesVisible(value);
    if (!value) {
      setFilterValues((prev) => {
        return { ...prev, main_category: undefined, sub_categories: [] };
      });
    }
  }, []);

  const [resetSearch, setResetSearch] = useState<number>(0);
  const [sortProperty, setSortProperty] = useState<ContentViewSortProperty>('title');
  const [sortDirection, setSortDirection] = useState<ContentViewSortDirection>('asc');
  const sortFn = useCallback(
    (page1: Page, page2: Page, sortProp?: ContentViewSortProperty, sortDir?: ContentViewSortDirection): number => {
      if (isEvent) {
        // sorted by backend
        return 0;
      }
      let a: string | number;
      let b: string | number;

      switch (sortProp || sortProperty) {
        case 'status':
          a = (page1.status ? tCommon(`Status.${page1.status}`) : '').toLowerCase();
          b = (page2.status ? tCommon(`Status.${page2.status}`) : '').toLowerCase();
          break;
        case 'updated_at':
          a = apiTimestampToDate(page1.updated_at || '')?.getTime() || 0;
          b = apiTimestampToDate(page2.updated_at || '')?.getTime() || 0;
          break;
        case 'title':
        default:
          a = String(page1.title || '').toLowerCase();
          b = String(page2.title || '').toLowerCase();
          break;
      }

      let order = 0;
      if (a < b) {
        order = -1;
      } else if (a > b) {
        order = 1;
      }
      return (sortDir || sortDirection) === 'asc' ? order : -order;
    },
    [sortProperty, sortDirection, tCommon, isEvent],
  );
  const pagesForSelectedLanguage = useMemo<Array<Page>>(() => {
    if (filterValues.lastEditedPagesView) {
      return lastEditedPages.filter((page) => page.locale === selectedSiteLanguage);
    }
    return pages.filter((page) => page.locale === selectedSiteLanguage);
  }, [filterValues.lastEditedPagesView, lastEditedPages, pages, selectedSiteLanguage]);

  const selectionState = useRecursiveSelectionState<Page>(
    pagesForSelectedLanguage,
    (page) => page.id || 0,
    (page) => page.parent_id || null,
    true,
  );

  const [movePagesInFolderMode, setMovePagesInFolderMode] = useState<boolean>(false);
  const [moveTargetFolder, setMoveTargetFolder] = useState<PageFolder | null>(null);
  const confirmMoveToFolder = useCallback(async () => {
    if (!selectionState.selectedItems.length) {
      return;
    }
    const requests = selectionState.selectedItems.map((page) => {
      if (!page.id) {
        return Promise.resolve(null);
      }
      return Api.movePageToFolder(page.id, moveTargetFolder?.id).fetchDirect(null);
    });
    await Promise.all(requests);
    reloadPages();
    setMovePagesInFolderMode(false);
    setMoveTargetFolder(null);
  }, [moveTargetFolder?.id, reloadPages, selectionState.selectedItems]);

  // TODO: 17/09/2024 move folder state here from folderview
  const [folderResponse, , reloadSiteFolders] = useApi(() => Api.getRootFolders(selectedSiteId), {
    folders: [] as PageFolder[],
  });

  const deletePage = useCallback(
    async (page: Page) => {
      if (!page?.id) {
        enqueueSnackbar(tCommon('error'), { variant: 'error' });
        return;
      }
      let deletedIdx = -1;
      let deletedElement: Page | null = null;
      setPages((prev) => {
        deletedIdx = prev.findIndex((p) => p.id === page.id);
        if (deletedIdx >= 0) {
          const cpy = [...prev];
          // eslint-disable-next-line prefer-destructuring
          deletedElement = cpy.splice(deletedIdx, 1)[0];
          return cpy;
        }
        return prev;
      });
      if (deletedElement) {
        const [, error] = await Api.deletePage(page.id).fetch();
        if (error) {
          enqueueSnackbar(tCommon('deleteFailed'), { variant: 'error' });
          setPages((prev: Array<Page>) => {
            const cpy = [...prev];
            cpy.splice(deletedIdx, 0, deletedElement as Page);
            return cpy;
          });
        } else {
          enqueueSnackbar(tCommon('deleteSuccess'), { variant: 'success' });
        }
      }
    },
    [enqueueSnackbar, setPages, tCommon],
  );

  const changePageStatus = useCallback(
    async (page: Page, newStatus: Status) => {
      if (!page.id) {
        enqueueSnackbar(tCommon('error'), { variant: 'error' });
        return;
      }
      const fullPage = await Api.getPage(page.id).fetchDirect(null);
      const pageCopy = deepCopy(fullPage);
      let savedPage: Page | null = null;
      if (!pageCopy) {
        enqueueSnackbar(tCommon('error'), { variant: 'error' });
        return;
      }
      pageCopy.status = newStatus;
      savedPage = await Api.savePage(pageCopy.site_id, pageCopy).fetchDirect(null);
      if (!savedPage) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        setPages((prev) => {
          return (prev.map((p: Page) => {
            if (p.id === savedPage?.id) {
              return savedPage;
            }
            return p;
          }) || prev) as Array<Page>;
        });
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
      }
    },
    [enqueueSnackbar, setPages, tCommon],
  );

  const openCreatePageModal = useCallback((parentPage?: Page) => {
    setCreatePageModalOpen((alreadyOpen) => {
      if (!alreadyOpen) {
        setCreatePageRoot(parentPage || null);
        return true;
      }
      return alreadyOpen;
    });
  }, []);

  const closeCreatePageModal = useCallback(() => {
    setCreatePageModalOpen(false);
    setCreatePageRoot(null);
  }, []);

  const toggleLastEditedPagesView = useCallback(() => {
    setFilterValues((prev) => ({
      ...defaultFilterValues,
      lastEditedPagesView: !prev.lastEditedPagesView,
    }));
    setResetSearch(resetSearch + 1);
  }, [resetSearch]);

  const setMainCategory = useCallback(
    (mainCategory?: MainCategory) => {
      setFilterValues((prev) => {
        return { ...prev, main_category: mainCategory };
      });
    },
    [setFilterValues],
  );

  const hasDraft = useCallback(
    (pageId: number) => {
      return pagesWithDraft.page_ids.includes(pageId);
    },
    [pagesWithDraft],
  );

  const [activePageFolder, setActivePageFolder] = useState<PageFolder | null>(null);

  const contextValue = useMemo<ContentViewContextValue>(
    () => ({
      contentContext,
      pages: pagesForSelectedLanguage,
      setPages,
      selectionState,
      selectionMode,
      setSelectionMode,
      movePagesMode,
      movePagesInFolderMode,
      setMovePagesMode,
      setMovePagesInFolderMode,
      toggleLastEditedPagesView,
      filterValues,
      setFilterValues,
      expandedPageIds,
      setExpandedPageIds,
      allExpanded: expandedPageIds.length === selectionState.itemCount,
      hasFilterApplied: Boolean(
        !!filterValues.query ||
          !!filterValues.statuses.length ||
          !!filterValues.main_category ||
          !!filterValues.sub_categories.length ||
          filterValues.lastEditedPagesView,
      ),
      reloadPages,
      deletePage,
      changePageStatus,
      openCreatePageModal,
      closeCreatePageModal,
      portalUrl,
      sortProperty,
      setSortProperty,
      sortDirection,
      setSortDirection,
      sortFn,
      hasDraft,
      activePageFolder,
      setActivePageFolder,
      siteFolders: folderResponse.folders,
      reloadSiteFolders,
    }),
    [
      contentContext,
      pagesForSelectedLanguage,
      setPages,
      selectionState,
      selectionMode,
      movePagesMode,
      movePagesInFolderMode,
      toggleLastEditedPagesView,
      filterValues,
      expandedPageIds,
      reloadPages,
      deletePage,
      changePageStatus,
      openCreatePageModal,
      closeCreatePageModal,
      portalUrl,
      sortProperty,
      sortDirection,
      sortFn,
      hasDraft,
      activePageFolder,
      folderResponse.folders,
      reloadSiteFolders,
    ],
  );

  useEffect(() => {
    // Construct domain
    if (domain) {
      const protocol = window.location.protocol || 'https:';
      const host = domain.name || window.location.hostname;
      const path = domain.path || '';
      const url = new URL(`${protocol}//${host}/${path}/${selectedSiteLanguage || ''}`);
      setPortalUrl(url.href);
    } else {
      setPortalUrl('#');
    }
  }, [domain, selectedSiteLanguage]);

  useEffect(() => {
    // Reload pages if the selected site changes
    if (!hasRendered.current.selectedSiteId) {
      hasRendered.current.selectedSiteId = true;
      return;
    }
    if (selectedSiteId) {
      reloadPages();
      reloadDomain();
    }
  }, [selectedSiteId, reloadPages, reloadDomain]);

  useEffect(() => {
    setLastEditedPages(pages.sort((page1, page2) => sortFn(page1, page2, 'updated_at', 'desc')).slice(0, 10));
  }, [pages, sortFn]);

  useEffect(() => {
    if (!hasRendered.current.contentContext) {
      hasRendered.current.contentContext = true;
      return;
    }
    setSelectionMode(false);
    setMovePagesMode(false);
    reloadPages();
  }, [contentContext, reloadPages]);

  useEffect(() => {
    if (!hasRendered.current.eventParams) {
      hasRendered.current.eventParams = true;
      return;
    }
    reloadPages();
  }, [eventParams, reloadPages]);

  const frontpage = useMemo<Page | undefined>(() => {
    return pages.find((page) => page.id === state.selectedSiteDefaultPageId) || undefined;
  }, [pages, state.selectedSiteDefaultPageId]);

  const handleSelectedLocationsChange = (value: number[]) => {
    setEventParams((prev) => {
      return { ...prev, location_ids: value };
    });
  };

  function sortEventCategoryByLabelName(a: SubCategory, b: SubCategory) {
    const aLabel = tCommon(`SubCategory.${a}`);
    const bLabel = tCommon(`SubCategory.${b}`);
    return aLabel.localeCompare(bLabel);
  }

  const setSubCategories = (subCategories: SubCategory[]) => {
    setFilterValues((prev) => {
      return { ...prev, sub_categories: subCategories };
    });
  };

  return (
    <ContentViewContext.Provider value={contextValue}>
      <Container p={6} fullWidth fullHeight column gap={1} top>
        <ToolbarHeader
          query={filterValues.query}
          onQueryChange={(v) => setFilterValues((prev) => ({ ...prev, query: v }))}
          resetSearch={resetSearch}
          heading={
            <Typography variant='h3' component='h1' fontSize={30}>
              {tComponents(
                `ContentView.${contentContext === ContentViewContentContext.EVENT ? 'eventsTitle' : 'pagesTitle'}`,
              )}
            </Typography>
          }
          topRight={
            <Container gap={1}>
              <Button
                variant={isEvent ? 'contained' : 'outlined'}
                color='success'
                startIcon={<MaterialSymbol name='add' />}
                onClick={() => openCreatePageModal(frontpage)}
                type='button'>
                {tComponents(isEvent ? 'CreatePageModal.NewEvent' : 'CreatePageModal.NewPage')}
              </Button>
              <Button
                variant='outlined'
                sx={{
                  px: 3,
                  whiteSpace: 'nowrap',
                  minWidth: 'fit-content',
                  backgroundColor: filterValues.lastEditedPagesView ? Styles.Colors.BLACK : Styles.Colors.LIGHT_GREY,
                  '&:hover': {
                    backgroundColor: filterValues.lastEditedPagesView
                      ? Styles.Colors.DARK_GREY
                      : Styles.Colors.LIGHTEST_GREY,
                  },
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                }}
                color={filterValues.lastEditedPagesView ? 'primary' : 'secondary'}
                onClick={() => toggleLastEditedPagesView()}
                startIcon={<MaterialSymbol name='history_toggle_off' />}>
                {tComponents('PageTreeToolbar.toggleLastEditedButtonLabel')}
              </Button>
              <Button
                variant='outlined'
                sx={{
                  px: 3,
                  whiteSpace: 'nowrap',
                  minWidth: 'fit-content',
                  backgroundColor: selectionMode ? Styles.Colors.BLACK : Styles.Colors.LIGHT_GREY,
                  '&:hover': {
                    backgroundColor: selectionMode ? Styles.Colors.DARK_GREY : Styles.Colors.LIGHTEST_GREY,
                  },
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                }}
                color={selectionMode ? 'primary' : 'secondary'}
                onClick={() => setSelectionMode((prevState) => !prevState)}>
                {tComponents('PageTreeToolbar.toggleSelectionModeButtonLabel')}
              </Button>
            </Container>
          }
          bottomRight={
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
              }}>
              {isEvent && (
                <>
                  <LocationSelector
                    fullWidth={false}
                    sx={{
                      minWidth: 200,
                      maxWidth: 450,
                    }}
                    value={eventParams.location_ids || []}
                    onChange={handleSelectedLocationsChange}
                    siteId={selectedSiteId}
                  />

                  <FormControl
                    variant='outlined'
                    sx={{
                      minWidth: '200px',
                      maxWidth: '300px',
                    }}>
                    <InputLabel>{tComponents('PageTreeToolbar.subCategoryLabel')}</InputLabel>
                    <Select
                      multiple
                      value={eventParams.sub_categories}
                      onChange={(e) =>
                        setEventParams((prev) => {
                          return { ...prev, sub_categories: e.target.value as SubCategory[] };
                        })
                      }
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        selected.map((category) => tCommon(`SubCategory.${category}`)).join(', ')
                      }>
                      {Object.values(EventCategory)
                        .sort(sortEventCategoryByLabelName)
                        .map((category) => (
                          <MenuItem key={category} value={category}>
                            <Checkbox checked={eventParams.sub_categories!.includes(category)} />
                            <ListItemText primary={tCommon(`SubCategory.${category}`)} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
          }
          boxProps={{ alignSelf: 'stretch' }}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            direction: 'row',
            justifyContent: 'space-between',
          }}>
          {isEvent && (
            <Container left sx={{}}>
              <SortSelector
                availableOptions={[SortType.EVENT_START, SortType.TITLE, SortType.UPDATED_AT]}
                sortOption={{
                  sortBy: eventParams.order_by || SortType.EVENT_START,
                  order: eventParams.order || SortDirection.ASC,
                }}
                setSortOption={(sort) => {
                  setEventParams((prev) => {
                    return { ...prev, order_by: sort.sortBy, order: sort.order };
                  });
                }}
              />
            </Container>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flex: 1,
              justifyContent: 'end',
            }}>
            <Box
              sx={{
                display: 'flex',
              }}>
              {[Status.PUBLISHED, Status.DRAFT, Status.ARCHIVED].map((status) => (
                <FormControlLabel
                  key={status}
                  control={
                    <Checkbox
                      disableRipple
                      checked={filterValues.statuses.includes(status)}
                      onChange={(event, checked) => {
                        setFilterValues((prev) => {
                          if (checked) {
                            return { ...prev, statuses: [...prev.statuses, status] };
                          }
                          return { ...prev, statuses: prev.statuses.filter((s) => s !== status) };
                        });
                      }}
                    />
                  }
                  label={tCommon(`Status.${status}`)}
                  labelPlacement='end'
                />
              ))}
              {!isEvent && (
                <Button
                  variant={filterCategoriesVisible ? 'contained' : 'outlined'}
                  color='secondary'
                  onClick={() => setFilterCategoriesVisible(!filterCategoriesVisible)}>
                  {filterCategoriesVisible && (
                    <MaterialSymbol
                      name='close'
                      sx={{
                        fontSize: 'large',
                      }}
                    />
                  )}
                  {tComponents('PageTreeToolbar.filterCategoriesButtonLabel')}
                </Button>
              )}
              {isEvent && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      checked={!eventParams.past_events}
                      onChange={(event, checked) => {
                        setEventParams((prev) => {
                          return { ...prev, past_events: !checked };
                        });
                      }}
                    />
                  }
                  label={tCommon(`Status.hide_past_events`)}
                  labelPlacement='end'
                />
              )}
            </Box>
            {selectionMode && <PageTreeBulkOperations />}
          </Box>
        </Box>
        <Container pb={4} mt={2} fullHeight fullWidth column top>
          {filterCategoriesVisible && (
            <FilterCategories
              mainCategory={filterValues.main_category}
              onChangeMainCategory={(category) => {
                setMainCategory(category);
                setSubCategories([]);
              }}
              subCategories={filterValues.sub_categories}
              onChangeSubCategories={setSubCategories}
            />
          )}
          {loading ? <Loader /> : <PageTree />}
        </Container>
      </Container>
      <CreatePageModal
        open={createPageModalOpen}
        parentPage={createPageRoot}
        pageFolderId={activePageFolder?.id}
        closeCreatePageModal={closeCreatePageModal}
        setPages={setPages}
        contentContext={contentContext}
      />
      {movePagesInFolderMode && (
        <Dialog
          open={movePagesInFolderMode}
          onClose={() => {
            setMovePagesInFolderMode(false);
            setMoveTargetFolder(null);
          }}>
          <Container
            fullWidth
            column
            sx={{
              p: 2,
              gap: 2,
              minWidth: '400px',
            }}>
            <Typography variant='h4'>{tComponents('PageTreeToolbar.MovePageToFolderDialog.Title')}</Typography>
            <PageFolderAutocomplete
              folders={folderResponse.folders}
              onChange={(targetFolder) => {
                if (targetFolder?.id) {
                  setMoveTargetFolder(targetFolder);
                } else {
                  setMoveTargetFolder(null);
                }
              }}
            />
            <Container>
              <Button onClick={() => setMovePagesInFolderMode(false)}>
                {tComponents('PageTreeToolbar.MovePageToFolderDialog.Cancel')}
              </Button>
              <Button color='primary' variant='contained' onClick={() => confirmMoveToFolder()}>
                {tComponents('PageTreeToolbar.MovePageToFolderDialog.ConfirmMove')}
              </Button>
            </Container>
          </Container>
        </Dialog>
      )}
    </ContentViewContext.Provider>
  );
};

export default ContentView;
